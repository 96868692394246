import React from "react";
import { graphql } from "gatsby";
import PortableText from '../components/portableText';

// import Hero from "../components/hero";
// import InfoRows from "../components/InfoRows";
// import CTAColumns from "../components/cta-columns";
// import CTA from "../components/cta";
// import Pricing from "../components/pricing";
// import { TopWave, BottomWave } from "../components/wave";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
query ProductionSubPageQuery($id: String!) {
    sanityCategorySubPage(_id: {eq: $id}) {
      title
      _key
      _rawMainContent
      seoFields {
        title
        description
      }
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawMainContent
    }
  }
`;

const CategorySubPage = (props) => {
  const { data, errors } = props;
  const {seoFields} = data.sanityCategorySubPage;
  const bannerImgFluid = data.sanityCategorySubPage.mainImage.asset.fluid;

  // this is ugly, find a better way
  let seoTitle = "";
  let seoDescription = "";

  if(seoFields !== null){

    if(seoFields.title !== null){
      seoTitle = seoFields.title;
    }

    if(seoFields.description !== null){
      seoDescription = seoFields.description;
    }
  }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout showBanner={true} bannerImgFluid={bannerImgFluid}  bannerTitle={data.sanityCategorySubPage.title}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        bodyAttr={{
          class: "",
        }}
      />
       <main  >
        <div className="py-24">
        {data.sanityCategorySubPage._rawMainContent &&
          <div className="container mx-auto text-white">
            <PortableText blocks={data.sanityCategorySubPage._rawMainContent} />
          </div>
        }
        </div>
      </main>
    </Layout>
  );
};

export default CategorySubPage;
